<template>
    <div>
        <b-modal id="modal-versioning" title="Changelogs" hide-footer>
            <div class="mx-2 my-2">
                <b-row>
                    <!-- <b-col cols="4">
                        <div class="text-light mb-1 text-center bg-primary rounded p-1">
                            Version {{ changelogs[0].version_code }}
                        </div>
                    </b-col> -->
                    <b-col cols="12">
                        <div class="text-dark fw-bold-600 mb-1 size48">
                            Released Update
                        </div>
                    </b-col>
                </b-row>
                <b-row v-for="(log, index) in changelogs" :key="index">
                    <b-col cols="4">
                        <div class="text-light text-center bg-primary rounded">
                            Version {{ log.version_code }}
                        </div>
                        <div class="text-dark text-center">{{ log.date }}</div>
                    </b-col>
                    <b-col cols="8">
                        <div v-for="changeLog in log.change_logs" :key="changeLog.id" class="text-dark custom-ul"
                            v-html="changeLog">
                        </div>
                    </b-col>
                    <b-col cols="12" class="mb-1">
                        <hr v-if="index !== changelogs.length - 1"/>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>
  
<script>
import { BModal, BRow, BCol } from 'bootstrap-vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        BModal,
        BRow,
        BCol,
    },
    props: {
        changelogs: Array,
    },
};
</script>

<style lang="scss" scoped>
.custom-ul {
    margin-left: -20px;
}
</style>